<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-12"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>